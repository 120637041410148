import React from 'react';
import './cskh.scss';
import cskh from '../../assets/asian.jpeg';
import { Image, Typography, Button } from 'antd';
import zalo from '../../assets/zalo.png';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import {tel} from '../../assets/telegram.png'
import zlcskh from '../../utils/connectCSKH';
export default function Cskh() {
  const router = useHistory();
  return (
    <div className="container cskh">
      <div className="header-content-cskh">
        <div>
          <Typography.Text className="text-head">CSKH</Typography.Text>
        </div>
      </div>
      <div className="headercskh">
        <Image src={cskh} preview={false} className="head-img" />
      </div>
      <div className="body">
        <div>
          <Typography.Text className="body-title">
            <h4 className="bodytext">
              CHĂM SÓC KHÁCH HÀNG
            </h4>
          </Typography.Text>
          <Typography.Text >
            <p>
              Thời gian làm việc: 08:00 đến 19:30
            </p>
          </Typography.Text>

          <Button
            className="cskh-btn"
            size="large"
            onClick={() => window.open('tel:1900 636 979', '_blank')}
          >
            <Typography.Text style={{ color: '#fff', fontWeight: 'bold' }}>
              LIÊN HỆ HOTLINE
            </Typography.Text>
          </Button>
          <div className="cskhrefimg">
            <motion.div
              className="mr-3"
              whileTap={{ scale: 0.95, x: -10 }}
              onClick={() => window.open('https://zalo.me/hana_vietnam', '_blank')}
            >
              <img alt={"Zalo"} height={'80px'} src={zalo} style={{paddingRight:  20, }}/>
            </motion.div>
            {/*<motion.div*/}
            {/*  className=""*/}
            {/*  whileTap={{ scale: 0.95, x: -10 }}*/}
            {/*  onClick={() => window.open('https://t.me/hana_vietnam', '_blank')}*/}
            {/*>*/}
            {/*  <img alt={"Telegram"} height={'60px'} src={tel} />*/}
            {/*</motion.div>*/}

          </div>
        </div>
      </div>

    </div>
  );
}
